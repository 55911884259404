import React from 'react'
import "./Main.css";
import photos from '../../images/photo';

function Main() {
    return (
        <div className="menu">
            <div className="text-box"><p>Կապ հաստատեք մեզ հետ . . .</p></div>
            <div className="container">
                <a href="https://telegram.me/+37436234343" className="link1"><img src ={photos.tellogo}></img></a>
                <a href="https://wa.me/+37436234343" className="link1"><img src={photos.whatslogo}></img></a>
                <a href="tel:011700992" className="link1"><img src ={photos.phonelogo}></img></a>
                <a href="viber://contact?number=37477000992"><img src = {photos.viberlogo}></img></a>
                <a href="tel:+37496234343"><img src = {photos.mobilelogo}></img></a>
                <a href="mailto:info.sdesignstudio@gmail.com"><img src = {photos.googlelogo}></img></a>
                <a href="http://sdesign.am/" target="_blank" rel="noreferrer"><img src ={photos.internetlogo}></img></a>
                <a href="https://www.google.ru/maps/place/%E2%80%9CStep+by+Step%E2%80%9D+Benevolent+Foundation/@40.2001827,44.4935185,17z/data=!3m1!4b1!4m5!3m4!1s0x406abd6a8d761e33:0xe58cc0811fddf45c!8m2!3d40.2001786!4d44.4957072" target="_blank" rel="noreferrer"><img src = {photos.location}></img></a>
                <a href="https://www.facebook.com/yerevanSdesign" target="_blank" rel="noreferrer"><img src = {photos.facebook}></img></a>
                <a href="https://www.instagram.com/sdesign.yerevan/" target="_blank" rel="noreferrer"><img src = {photos.instagram}></img></a>
                <a href="https://www.behance.net/sdesignstudio1" target="_blank" rel="noreferrer"><img src = {photos.be}></img></a>

            </div>
            <div className="main_line">
                <hr className="new-line"></hr>
            </div>
            <div className="prow">
                <div className="we_provide">
                    <p className="text_2">Դիզայն</p>
                </div>
                <div className="we_provide_2">
                    <p className="text_2">լայնաֆորմատ տպագրություն</p>
                </div>
                <div className="we_provide_3">
                    <p className="text_2">Ծավալային տառեր</p>
                </div>
                <div className="we_provide_4">
                    <p className="text_2">Ինտերերի և Էքստերիերի դիզայն</p>
                </div>
                <div className="we_provide_5">
                    <p className="text_2">Վահանակներ</p>
                </div>
                <div className="we_provide_6">
                    <p className="text_2">Ցուցանակներ</p>
                </div>
                <div className="we_provide_7">
                    <p className="text_2">3D Փորագրություն</p>
                </div>
                <div className="we_provide_8">
                    <p className="text_2">Լազերային և ֆրեզերային աշխատանքներ</p>
                </div>
            </div>
        </div>

    )
}

export default Main