import { ReactComponent as Logo } from "../../images/Group.svg";
import './Header.css';

function Header() {
    return (
        <div className="header">
            <a href="#"><Logo className="logo"/></a>
        </div>
    )
}

export default Header;