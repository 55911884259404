import tellogo from './photo/telegram.png'
import whatslogo from './photo/whatsapp.png'
import phonelogo from './photo/phone.png'
import viberlogo from './photo/viber.png'
import mobilelogo from './photo/mobile.png'
import googlelogo from './photo/google.png'
import internetlogo from './photo/internet.png'
import location from './photo/location.png'
import facebook from './photo/facebook.png'
import instagram from './photo/instagram.png'
import be from './photo/be.png'

const photos = {
    tellogo,
    whatslogo,
    phonelogo,
    viberlogo,
    mobilelogo,
    googlelogo,
    internetlogo,
    location,
    facebook,
    instagram,
    be
}

export default photos